@charset "utf-8";



// Our variables
$base-font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
$base-font-size:   20px;
$base-font-weight: 300;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fffff8;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    1500px;
$on-phone:          550px;
$on-palm:          800px;
$on-laptop:        800px;
$on-regular:        1000px;

//Author Colors
$armantrout: blue;
$beasley: green;
$gerstler: gray;
$gluck: pink;
$mcmichael: aquamarine;
$mohammad: gold;
$pinsky: brown;
$ryan: purple;
$strickland: red;
$vanwinckel: cyan;
$wrigley: teal;


/* buttons to theme */
 

$primary: aquamarine;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    
    "primary": $primary
    
  ),
  $theme-colors
);


// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "theme-colors",
        "tufte",
        "syntax-highlighting"
;
