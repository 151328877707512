.hide-laptop{
   @include media-query($on-laptop) {

         display:none;
     }

}

.hide-palm{
   @include media-query($on-palm) {

         display:none;
     }

}

/**
 * Site headline
 */
.home{background-color: transparent;  background-repeat: no-repeat;
    background-size: cover;height: 1000px; margin-top:-32px;
    
     @include media-query($on-palm) {

         height:500px;
     }
     }
     .divider{background-color: transparent;  background-repeat: no-repeat;
        background-size: cover;height: 300px; 
        background-position: center; 
        
         @include media-query($on-palm) {
    
             height:100px;
         }
         }

#homeback{background-image: url('/ctrl-shift/images/splash/mcmichael.jpg');}
#divider1{background-image: url('/ctrl-shift/images/splash/gluck.jpg');}
#divider2{background-image: url('/ctrl-shift/images/splash/beasley.jpg');}
#divider3{background-image: url('/ctrl-shift/images/splash/gerstler.jpg');}
#divider4{background-image: url('/ctrl-shift/images/splash/wrigley.jpg');}
#divider5{background-image: url('/ctrl-shift/images/splash/vanwinckel.jpg');}
#divider6{background-image: url('/ctrl-shift/images/splash/ryan.jpg');}
   .site-headline{  color: white;
    font-size: 90px;
    
    font-weight: 700;
      margin: 0 12%;
    line-height: 93px;
    letter-spacing: -3px;
    
       @include media-query($on-palm) {
           font-size:30px;
           line-height:38px;
letter-spacing: -1px;
       }
     }

.site-subline{    color: white;
    margin: 2% 12%;

    font-size: 22px;
    line-height: 30px;
    .action-button{border:none; }
     @include media-query($on-palm) {
    font-size: 14px;
    line-height: 24px;
           padding:5px;}
           
    }

    .white-space{height:200px;  @include media-query($on-palm) {
          height:30px;
          
       }}

.main-heading{font-size: 50px;}



/**
 * Custom Buttons
 */
.action-button {
        background-attachment: scroll;
    background-clip: border-box;
    background-image: none;
    background-origin: padding-box;
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto auto;
    color: inherit;
    display: inline-block;

    font-size: 16px;
    height: auto;
    letter-spacing: 0.03em;
    line-height: 1;
    padding: 10px 20px;
    text-transform: uppercase;
    color: #ffcbcb !important;
    border: 1px #ffcbcb solid;
    border-radius:6px;
    background: #4c525f;
    &:hover{background: #3a3f49;text-decoration:none;}
}

.download-button{float:right; margin:10px;}

.home-section{
    width:100%;
    min-height:300px;
    padding:30px 0px;
    p,h3,h2,h4{width:85%; max-width:900px;margin:0% auto;}
    form{display: block;
        select{ font-size:20px; text-align: center;}}
    
}

.findings-section{
    width:100%;
    min-height:300px;
    padding:30px 0px;
    p,h3,h2,h4{width:85%; max-width:900px;margin:0% auto 2rem;}
    form{display: block;
        select{ font-size:20px; text-align: center;}}
    
}
.about-section{
    width:100%;
    min-height:300px;
    padding:30px 5%;
    
 overflow:auto;
 @include media-query($on-palm){ width: 100%;
 min-height: 300px;
 padding: 30px 1%;
 overflow: auto;}
    form{display: inline;
        select{ font-size:20px; text-align: center;}}
    
}

.not-so-wide{
    float:none !important;
    p,h3,h2,h4{width:85%; max-width:900px;margin:0% auto 2rem;}
}

.home-int, .home-process,{
background:#5b616f;
color:white !important;
    overflow: hidden;
.image-container{
    width:65%;
        overflow: hidden;
        margin:0% auto;
        a:hover{text-decoration:none;}
    @include media-query($on-palm) {
    width:100%;
    }



}
.action-button{    color: #ffcbcb !important;
    border: 1px #ffcbcb solid;}
}
.about-process, .about-int{
    background:#5b616f;
    color:white !important;
    .action-button,{    color: #ffcbcb !important;
        border: 1px #ffcbcb solid;}
        a{    color: #ffcbcb !important;
   }   }

   .action-button-color-only{    
    color: #ffcbcb !important;
    border: 1px #ffcbcb solid;
    background: #4c525f;}

.portraits{width:23%;margin:0px;padding:0px;max-height:360px;float:left;    overflow: hidden;
img:hover{ background:white;}
@include media-query($on-laptop) {
    width:50%;
    }
}

.name{font-size:20px; color:#d0cbcb; text-decoration: none !important; 
    &:hover{text-decoration: none !important; }
}
.home-anal{
    .image-container{
        width:100%;
        @include media-query($on-palm) {
        width:100%;
        }
    img{width:10%;margin:10px 0px;padding:0px;transition: .5s ease;}
    }
}



.container-proc {
  position: relative;
  width: 60%;
  overflow:hidden;
  margin:0% auto;
   @include media-query($on-palm) {
        width:100%;
        }
}

.image {
  display: block;
  max-width:23%;margin:0px;padding:0px;max-height:100px;
  height: auto;
  float:left;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}



.container-proc:hover .overlay {
  opacity: .75;
}

.text {
  color: black;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  
}



.selected {
      
    border: 1px black solid;
}

    /**
 * Site header
 */
.site-header {

    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
       backface-visibility: hidden;
    border-color: #ffffff;
    box-sizing: border-box;
    color: #ffffff;
    max-width: 100%;
    padding: 20px 40px;

    top: 0;
    transition: background-color 300ms ease 0s, color 260ms ease 0s, padding 180ms ease 0s;
    width: 100%;
    z-index: 12;
}


 /**
 * Poet Feature
 */

.poet-menu{
padding: 0px 0px; 
text-align:center; 
@include media-query($on-palm) {
    padding: 0px 0px; 
}
p{
  
 
line-height: 60px; 

 a{font-size:24px;color: black; font-style: italic; white-space: nowrap; margin-right:18px; white-space: nowrap;
  }
@include media-query($on-palm) {
    float:none; width:100%;
    column-count:1;
}}
}

.poet-feature
{
    overflow-y: hidden;
    max-width: 1500px;
   min-height:800px;
    
    
padding: 10px 3%;
 margin:0% auto;  
    .backtotop{font-size:10px; text-align:right; margin:0px;display:none;}
//    a{color:#3e69a0 !important;}
    .column{float:left; background: transparent; padding:5px; width:46%;padding: 5px 1%; margin-right:10px;
    img{max-height:400px; max-width: 90%;}
    p{max-width:90%;}
    .bio{font-size:13px;}
    }
.portrait{width:100%; height:auto;}

   @include media-query($on-palm) {
.column{padding: 20px; float:none;width:100%}
.right-column{float:none;}
border-top: 4px #333 solid; 
height: auto; 
padding:10px 0px;
   }

}
 .odd{background:#fff;}
   .even{background: #f2f2f2;}
.poet-name{font-size:35px;}
.quote{font-style:italic;font-size:20px;}
.variation{display:none;}


.attribution{text-align:right; font-size:14px;}



.process-viz{
width:33%;
padding:5px; 
float:right;
 @include media-query($on-palm) {
    width:100%;
        padding:0px; 
    }
    .part{width:100%; float:left;display:inline;text-align: center;
         
    @include media-query($on-palm) {
        width:95%;
        margin-bottom:25px;
       padding:7px ; 
    }
    img{max-height:100px;}
    }
}


.process-nar{
    width: 57%;
    float: left;
    margin: 1%;
    @include media-query($on-palm) {
        width: 95%;
        padding: 10px;
    }
    p{
    @include media-query($on-palm) {
        column-count:1;
    }}
    }


.graph .process-viz{
width:100%;

    .part{width:22%; float:left;display:inline;
      
    @include media-query($on-palm) {
        width:100%;
       
    }
      img{max-height:50px;}
      
       
    }
.row-name{width:10%; float:left;display:inline;margin-right:1%;
       @include media-query($on-palm) {
        width:100%;
    margin-right:0%;}
    }
.row-wrapper{background:#999;}
    .graph-row{
    height: auto;
    overflow-y: auto;
   
    
        padding:40px 30px;
    }
}

.hidden-lg{
    display:none;
    @include media-query($on-palm) {
          display:block;}

}
.hidden-all{display:none !important;}

.site-title {
    font-size: 36px;
    font-weight: 300;
    line-height: 65px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
        text-shadow: 0 0 0.1em #fff, 0 0 0.2em #fff, 0 0 0.3em #fff, 0 0 0.4em #f7f, 0 0 0.6em #f0f, 0 0 0.8em #f0f, 0 0 1em #f0f, 0 0 1.2em #f0f;
    color: #fff !important;
    
 &:hover {
        color: #fff;
        text-decoration: none;
        text-shadow: 0 0 0.2em #fff, 0 0 0.5em #fff, 0 0 0.4em #fff, 0 0 0.6em #f7f, 0 0 0.8em #f7f, 0 0 1em #f7f, 0 0 1.2em #f7f, 0 0 1.4em #f7f;
    }
    /*&,
    &:visited {
        color: $grey-color-dark;
    }*/
}

.page-title {
    display:none;
       @include media-query($on-palm) {
        
       display:block;
    }
}

.site-nav-ctrl {
    float: right;
    margin-left:60px;
    margin-top:5px;
    padding-top:15px;


    .menu-icon {
        display: none;
    }

    .page-link-ctrl {
        color: $text-color;
        line-height: $base-line-height;
            padding:6px 12px;
    font-size:14px;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
        
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link-ctrl {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}
 .home    .page-link-ctrl {
        color: white !important;
            @include media-query($on-palm) {
            color: $text-color !important;}
        }

#breadcrumbs{margin-left:5%;}


/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
    margin: 0 5%;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
        max-width: 300px;
        float:left;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}

.listing a{
    list-style: none;
    border: 1px solid #999;
    padding: 20px;
    width: 25%;
    float:left;
    margin:20px;
    img{max-height:205px;}



    @include media-query($on-regular) {
       width: 39%;
       margin:2%;
    }

     @include media-query($on-palm) {
       width: 80%;
    }
}
.anal-listing a{
    list-style: none;
    border: 1px solid #999;
    padding: 5px;
    width: 41%;
    border-radius: 0px;
    float: left;
    margin: 5px;
    background: #4c525fcc;
    color: #fff !important;
    border-radius: 6px;
    &:hover{color: #8c6470 !important;}
img{max-height:100px;}}


.questions{width:40%; margin-right:5px;}
.respondents{width:30%; margin-right:5px;}
.icons{width:20%} 
.anal-div{
    float:left; 
    @include media-query($on-palm) {
        width: 95%;
     }
}
/**.listing a:hover{
    background:#ccc;
    color:white;} 
     */



 .interview-button{    float: right;
    font-size: 30px;
    border: 1px solid aliceblue;
    color: #fff;
    background:  #a29783;
    border-radius: 10px;
    padding: 10px;}

     .interview-button:hover{
         text-decoration: none;
         background: #bdaf97;
           color: #fff;}

           .interview-button:visited{
               color:#fff;
           }

 .newpoem-button{    float: right;
    font-size: 30px;
    border: 1px solid #10e410;
    color: #fff;
    background: transparent;
    margin-right:2%;
    padding: 10px;}

     .newpoem-button:hover{
         text-decoration: none;
        
         border: 1px solid #f0f;
         text-shadow: 0 0 0.1em #fff, 0 0 0.2em #fff, 0 0 0.3em #fff, 0 0 0.4em #f7f, 0 0 0.6em #f0f, 0 0 0.8em #f0f, 0 0 1em #f0f, 0 0 1.2em #f0f;
           color: #fff;}

           .newpoem-button:visited{
               color:#fff;
           }

.question-body{margin: 0% auto; max-width: 97%;}

/*
.armantrout{color: white !important; background: $armantrout}
.beasley{color: white; background: $beasley;}
.gerstler{color: white; background: $gerstler;}
.gluck{color: white; background: $gluck;}
.mcmichael{color: white; background: $mcmichael;}
.mohammad{color: white; background: $mohammad;}
.pinsky{color: white;  background: $pinsky;}
.ryan{color: white; background: $ryan;}
.strickland{color: white; background: $strickland;}
.vanwinckel{color: white; background: $vanwinckel;}
.wrigley{color: white; background: $wrigley;}

.armantrout-hov:hover{background: $armantrout}
.beasley-hov:hover{background: $beasley;}
.gerstler-hov:hover{background: $gerstler;}
.gluck-hov:hover{background: $gluck;}
.mcmichael-hov:hover{background: $mcmichael;}
.mohammad-hov:hover{background: $mohammad;}
.pinsky-hov:hover{ background: $pinsky;}
.ryan-hov:hover{background: $ryan;}
.strickland-hov:hover{background: $strickland;}
.vanwinckel-hov:hover{background: $vanwinckel;}
.wrigley-hov:hover{background: $wrigley;}
*/


.answer{    width: 65%;
max-width:700px;
    float: left;
    
    margin: 2% 15%;
    border-radius: 5px; 
   
    opacity: .9; 
    h2{ padding: 10px; }
    a{margin:0% auto;float:right;}
 
    p{padding: 10px;}
    h2 img{margin-left:20px; width:75px; height:auto; float:right;}
          @include media-query($on-palm) {
     width:100%;
     margin:2% 1%;}
    }
.question-body .answer{
 margin: 2% 16%;
@include media-query($on-palm) {
    margin:2% 1%;
 }
    }
    .question-people .answer:first-of-type{
 margin: 2% 1%;
 

    }
     

.question-pic{float:left; width:15%; margin-right:15px;}
.center{text-align:Center;}

.attribution{text-align:right;}
a{ color: black !important; padding-bottom: 3px;;}
a:hover{background:none;}



/*

.site-nav-ctrl ul {
  text-align: left;
  display: inline;
  margin: 0;
  padding: 15px 4px 17px 0;
  list-style: none;
 
}
.site-nav-ctrl ul li {
  font: normal 14px/20px helvetica;
  display: inline-block;
  margin-right: -4px;
  position: relative;
  padding: 15px 20px;
  min-width:120px;
  text-align: center;
 
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.site-nav-ctrl ul li:hover, .site-nav-ctrl ul li:hover{
  
  color: #fff !important;
}
.site-nav-ctrl ul li ul {
  padding: 0;
  position: absolute;
  top: 48px;
  left: 0;
  width: 150px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transiton: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -transition: opacity 0.2s;
}
.site-nav-ctrl ul li ul li { 
  background: #555; 
  display: block; 
  color: #fff !important;
}
.site-nav-ctrl ul li ul li:hover { background: #666; }
.site-nav-ctrl ul li:hover ul {
  display: block;
  opacity: 1;
  visibility: visible;
}*/

.pink-neon{text-shadow: 0 0 0.1em #fff, 0 0 0.2em #fff, 0 0 0.3em #fff, 0 0 0.4em #f7f, 0 0 0.6em #f0f, 0 0 0.8em #f0f, 0 0 1em #f0f, 0 0 1.2em #f0f;
    color: #fff;}

.green-neon{text-shadow: 0 0 0.1em #fff, 0 0 0.2em #fff, 0 0 0.3em #fff, 0 0 0.4em #10e410, 0 0 0.6em #10e410, 0 0 0.8em #10e410, 0 0 1em #10e410, 0 0 1.2em #10e410;
    color: #fff !important;}

    html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
    background:white;
}

.genpoem-portrait{width:25%; margin-right:23px;float:left;
    @include media-query($on-palm) {
        display:none;
     }
}

.genpoem-content, .about-content{margin:0% 5%; float:left;
    h4{font-style: italic;}
    @include media-query($on-laptop) {
            margin: auto;
    width: 90%;float:none;
     }
}

.transcript-content{margin:0% 5%; float:left; max-width:680px;
    h4{font-style: italic;}
    @include media-query($on-laptop) {
            margin: auto;
    width: 90%;float:none;
     }
}



    .about-people{float:right; width:25%; padding:12px;}
#mymap{
   @include media-query($on-laptop) {
        display:none;
     }
}
    #mappic{
        display:none;
   @include media-query($on-laptop) {
        display:block;
        width:25%;
     }
     @include media-query($on-palm) {
        display:block;
        width:100%;
     }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
    background:#444852;
    color:white; 
    a{ color:#ffcbcb !important; }
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 17px;
    color: #e8e8e8;
    margin-left: $spacing-unit / 2 ;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(60% - (#{$spacing-unit} / 2));
    width:         calc(60% - (#{$spacing-unit} / 2));
}


.footer-col-3 {
    width: -webkit-calc(40% - (#{$spacing-unit} / 2));
    width:         calc(40% - (#{$spacing-unit} / 2));
}

@include media-query($on-regular) {
    .footer-col-1,
    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@media print {
    
      html, body {
        height:100%; 
        margin: 0 !important; 
        padding: 0 !important;
        overflow: visible;
        display:block;
      }

      .transcript-content{
          height:auto;
          min-height:5000px;
          overflow:visible;
          display:block;
      }
    
    }
    #gobutton, .searchinput{
        cursor: pointer;
    }
    
    .btn-ctrl {
        background-color: transparent;
        background-image: none;
        border: 4px double #f0f;
        &:hover{background: #f0f; color:white; }
    }

    .btn-outline-ctrl {background-color: transparent;
        background-image: none;
        border: 1px ridge #f0f;
        &:hover{  border: 2px ridge #f0f;}
    }
    td{vertical-align: top;padding:15px;}
    .transcript-iframe-wrapper{
        position: fixed;
          right: 10px;
          height: 200px;
          width: 370px;
          @include media-query($on-regular) {
            position:relative; 
          }
      }
  .timestamp{font-size:10px;}
  .grey {
    color: grey;
    height: 3px;
    width: 80%;
    line-height: 2px;
    float:left;
    margin-bottom:0px !important;
}
.anchored-highlight{background:aquamarine !important;}

  .featured{display:block;float:none; clear:both;}
 .line{display: block; font-size:8px; float:left;}
 .grey .words, .grey .timestamp, .grey .speaker, .grey .line {
    display: inline;
    font-size: 3px;
    padding: 0px 5px;
}

.filteroptions, .searchinput{font-size:24px; 
    @include media-query($on-palm) { font-size:18px;}}
    
.notes-container{
list-style:disc;
    li{margin:10px 0px;}
}


    .youtube {
        background-color: #000;
        margin-bottom: 30px;
        position: relative;
        padding-top: 56.25%;
        overflow: hidden;
        cursor: pointer;
    }
    .youtube img {
        width: 100%;
        top: -16.84%;
        left: 0;
        opacity: 0.7;
    }
    .youtube .play-button {
        width: 90px;
        height: 60px;
        background-color: #333;
        box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
        z-index: 1;
        opacity: 0.8;
        border-radius: 6px;
    }
    .youtube .play-button:before {
        content: "";
        border-style: solid;
        border-width: 15px 0 15px 26.0px;
        border-color: transparent transparent transparent #fff;
    }
    .youtube img,
    .youtube .play-button {
        cursor: pointer;
    }
    .youtube img,
    .youtube iframe,
    .youtube .play-button,
    .youtube .play-button:before {
        position: absolute;
    }
    .youtube .play-button,
    .youtube .play-button:before {
        top: 50%;
        left: 50%;
        transform: translate3d( -50%, -50%, 0 );
    }
    .youtube iframe {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
    .about-content a{   color: #ffcbcb !important;}
    .about-white-background{
        a{color:#fc74b3  !important;}
    }.peeps{    width: 32%;
        float: left;
        padding-right: 35px;
        border-right: 1px dotted black;
        margin-left:5%;
        hr{display: none;}
        a{color:#fc74b3 !important;}
        @include media-query($on-regular) {
            width:100%;
            padding:10px;
            margin:0;
            border:none;
            hr{display: block;}
            }}
    .meth{width:40%; float:left; padding:0px 0px 0px 90px;
        a{color:#fc74b3 !important;}
                @include media-query($on-regular) {
                    width:100%;
                    padding:10px;
                    }}

.back_to_top {
    position: fixed;
    top: 90%;
    right: 0px;
    width: 40px;
    margin: -20px;
    border-radius: 20px;
    background: #fc74b3;
    color: white;
    height: 26px;
    padding: 10px;
    z-index: 10; }
    
    .back_to_top a {
    color: white !important; }
                      

    
#scroll-to-top {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 15%; /* Place the button at the bottom of the page */
    right: -10px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 5px 23px 5px 10px;
    border-radius: 10px;
    font-size: 40px;
    }